/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Container,
    Form,
    Modal,
    Row,
    Col,
    InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select-animated.cjs';

import { useReportContext } from '../../../Context/index';
import CustomInput from '../../Common/CustomInput/CustomInput';
import { input, clubData, discussionsData, value } from '../interface';
import adminAPIdData from '../ReportsData';
import { globalURLS } from '../../.././Global/urls.js';

const ReportModal: React.FC = () => {
    const {
        reportData,
        reportModal,
        setReportModal,
        selectedReport,
        setSelectedReport,
        toastShow,
        setToastShow,
        errorToastShow,
        setErrorToastShow,
        axiosRespData,
        setAxiosRespData,
    } = useReportContext();

    const [reqBody, setReqBody] = useState();
    const [clubs, setClubs] = useState([]);
    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [value, setValue] = useState<value>();
    const [validated, setValidated] = useState(false);

    const animatedComponents = makeAnimated();

    const handleOnChange = (e: any, inputType: string, idx: number) => {
        const { value } = e.target || e;
        const clonedData = { ...selectedReport };
        clonedData.inputs[idx].reqBody.value = value?.includes('[')
            ? ''
            : value || e;
        setSelectedReport(clonedData);
    };
    // console.log('selectedReport', selectedReport);

    useEffect(() => {
        if (selectedReport?.inputs) {
            const finalMappedData: string[] = selectedReport?.inputs.map(
                input => {
                    if (
                        conditions.every(
                            condition =>
                                !input?.reqBody?.label.includes(condition),
                        )
                    ) {
                        const obj: any = {};
                        obj[input?.reqBody?.label] = input?.reqBody?.value;
                        return obj;
                    }

                    if (
                        conditions.some(condition =>
                            input.reqBody.label.includes(condition),
                        )
                    ) {
                        const obj: any = {};
                        obj[input?.reqBody?.label] = value?.value;
                        return obj;
                    }
                },
            );

            let finalData;

            if (finalMappedData && finalMappedData.length) {
                finalData = Object.assign({}, ...finalMappedData);
                setReqBody(finalData);
            }

            // console.log('finalData', finalData);
        }
    }, [selectedReport, value]);

    useEffect(() => {
        if (reportData?.endPoint) {
            const respData = adminAPIdData.find(
                el => el.endPoint === reportData?.endPoint,
            );

            setSelectedReport(respData);
        }

        const fetchData = async () => {
            const clubsData = await axios.get(globalURLS.clubsJSONURL);
            const discussionsData = await axios.get(
                globalURLS.discussionsJSONURL,
            );

            if (clubsData?.data) {
                setClubs(
                    clubsData?.data?.map((el: clubData) => ({
                        value: el.clubid,
                        label: el.name,
                    })),
                );
            }

            if (discussionsData?.data) {
                setDiscussions(
                    discussionsData?.data?.map((el: discussionsData) => ({
                        value: el.discussionid,
                        label: `${el.name}${el.startdate ? ` | ${new Date(el.startdate).toLocaleString()}` : ``}`,
                    })),
                );
            }
        };
        fetchData();
    }, [reportData?.endPoint]);

    const handleValue = (val: value) => {
        setValue(val);
    };

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);

        // if (reqBody) {
        try {
            const resp = await axios.post(
                `${globalURLS.baseURL}${reportData.endPoint}`,
                reqBody,
                globalURLS.config,
            );
            // console.log('resp', resp);
            if (resp?.data?.data && resp?.data?.success === true) {
                setToastShow(true); setReportModal(false);
                // console.log('resp?.data?.data', resp?.data?.data);
                // handleToastShow();
                setAxiosRespData(resp?.data?.data);
                // setAlertModal(false);
            } else {
                setErrorToastShow(true);
            }
        } catch (error) {
            setErrorToastShow(true);
        }
        finally { setIsLoading(false);  }
        // }
    };

    const conditions = ['discussionid', 'clubid'];
    return (
        <Container className="news-container">
            <Col>
                <Modal show={reportModal} animation={false}>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Modal.Header>
                            <Modal.Title className="mb-0">
                                {`${reportData?.name}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                {selectedReport?.inputs &&
                                    selectedReport?.inputs.map(
                                        (input: input, idx: number) => {
                                            return (
                                                <Row
                                                    key={idx}
                                                    style={{ width: '25vw' }}
                                                >
                                                    {conditions.every(
                                                        condition =>
                                                            !input.reqBody.label.includes(
                                                                condition,
                                                            ),
                                                    ) && (
                                                        <CustomInput
                                                            classNames={''}
                                                            textArea={'short'}
                                                            type={input.type}
                                                            min={input?.minDate}
                                                            errorMessage={`Please Enter A ${input.reqBody.placeHolder}`}
                                                            label={
                                                                input.reqBody
                                                                    .label
                                                            }
                                                            placeHolder={
                                                                input.reqBody
                                                                    .placeHolder
                                                            }
                                                            value={
                                                                input?.reqBody
                                                                    ?.value
                                                                    ? input
                                                                          ?.reqBody
                                                                          ?.value
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                e: React.ChangeEventHandler<HTMLInputElement>,
                                                            ) => {
                                                                // console.log(
                                                                //     'input.reqBody',
                                                                //     input.reqBody,
                                                                // );

                                                                handleOnChange(
                                                                    e,
                                                                    input.type,
                                                                    idx,
                                                                );
                                                            }}
                                                            onSelect={(
                                                                e: any,
                                                            ) => {
                                                                handleOnChange(
                                                                    e,
                                                                    input.type,
                                                                    idx,
                                                                );
                                                            }}
                                                            synopsis={''}
                                                            required={
                                                                input.reqBody
                                                                    .required
                                                            }
                                                            disabled={false}
                                                            readOnly={false}
                                                            defaultValue={''}
                                                            dropDown={{
                                                                title: input
                                                                    ?.reqBody
                                                                    ?.label,
                                                                dropDownItems:
                                                                    input
                                                                        ?.reqBody
                                                                        ?.filterBy,
                                                            }}
                                                        />
                                                    )}
                                                    {conditions.some(
                                                        condition =>
                                                            input.reqBody.label.includes(
                                                                condition,
                                                            ),
                                                    ) && (
                                                        <InputGroup className="row mt-3">
                                                            <Form.Label htmlFor="basic-url">
                                                                {`Select ${input.reqBody.label.replace(
                                                                    'id',
                                                                    '',
                                                                )}`}
                                                            </Form.Label>
                                                            <Select
                                                                closeMenuOnSelect={
                                                                    true
                                                                }
                                                                components={
                                                                    animatedComponents
                                                                }
                                                                onChange={(
                                                                    val: value,
                                                                ) =>
                                                                    handleValue(
                                                                        val,
                                                                    )
                                                                }
                                                                isSearchable
                                                                options={JSON.parse(
                                                                    JSON.stringify(
                                                                        input
                                                                            .reqBody
                                                                            .label ===
                                                                            'clubid'
                                                                            ? clubs
                                                                            : discussions,
                                                                    ),
                                                                )}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select a
                                                                Input.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    )}
                                                </Row>
                                            );
                                        },
                                    )}
                            </>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setReportModal(false),
                                        setSelectedReport(null);
                                }}
                            >
                                Close
                            </Button>

                            <Button
                                variant={
                                    isLoading
                                        ? 'warning'
                                        : !isLoading
                                        ? 'primary'
                                        : 'success'
                                }
                                type="submit"
                                disabled={isLoading}
                                // onClick={event => handleSubmit(event)}
                            >
                                {isLoading ? `Processing...` : 'Get Report'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Col>
        </Container>
    );
};

export default ReportModal;
